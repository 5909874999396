<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Candidate detail edits</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Candidate detail edits
                </div>

              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn x-large dark @click="viewArchivedProfileEdits()">View archived profile edits</v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>All profile edits are managed by the local SCORE team.</p>
            <div class="table-responsive">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">

                  <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                    <v-autocomplete
                        label="SCORE"
                        v-model="search.score_id"
                        v-on:keyup.enter="searchUpdateRequest"
                        @input="search.score_id = $event !== null ? $event : ''"
                        item-text="name"
                        item-value="id"
                        :items="scores"
                        outlined
                        dense
                        clearable>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <v-text-field
                        label="Search by name"
                        v-model="search.candidate_info"
                        v-on:keyup.enter="searchUpdateRequest"
                        @input="search.candidate_info = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-menu v-model="request_date_menu" :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field  v-on:keyup.enter="searchUpdateRequest"
                                       v-model="search.request_date"
                                       label="Date of request" clearable
                                       prepend-inner-icon="mdi-calendar"
                                       readonly dense outlined
                                       v-bind="attrs"
                                       v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.request_date"
                          @input="request_date_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>


                  <v-col cols="12" sm="12" :md="currentUser.access_type == 'score' ? 5 : 2" class="text-right">
                    <v-btn
                        @click="searchUpdateRequest"
                        class="text-white btn btn-primary"
                        :loading="isLoading">
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>

              </div>

              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! isLoading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Date requested</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Requested by</th>
                  <th class="px-3">Type</th>
                  <th class="px-3" v-if="currentUser.access_type!='score'">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <tr v-for="(item, index) in update_requests" :key="index" v-if="update_requests.length>0">
                    <td class="px-3">
                        <a href="#" @click="reviewRequest(item.id)" class="text-primary ml-2 font-weight-medium d-block font-size-lg">
                       {{ item.requested_date }}
                      </a>
                    </td>
                    <td class="px-3">
                        <a href="#" @click="getCandidateSummary(item.exam_key)" class="ml-2 font-weight-bolder d-block font-size-lg">
                        {{ item.exam_key }}
                      </a>
                      <span>{{item.full_name}}</span><br/>
                      <span><b>Enrolment date:</b> {{item.enrolment_date}}</span><br/>
                      <span><b>Exam name:</b> {{item.product_name}}</span>
                    </td>
                    <td class="px-3">
                        <a href="#" @click="viewAccountHolderInfo(item.enroler_id)" class="ml-2 font-weight-bolder d-block font-size-lg">
                        {{ item.enroler_full_name }}
                      </a>
                    </td>
                    <td class="px-3">
                        <span class="font-weight-black d-block font-size-lg">
                        {{ item.type_text }}
                      </span>
                    </td>
                    <td class="px-3" v-if="currentUser.access_type!='score'">
                      {{ item.score_prefix }}
                    </td>
                    <td class="px-3">
                           <span class="badge"
                                 v-bind:class="{ 'badge-success': item.status=='approved', 'badge-danger': item.status=='rejected','badge-warning':item.status=='received' }">
                             {{ item.status_text }}
                           </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="reviewRequest(item.id)" class="navi-link">
                                  <span class="navi-icon">

                                    <i class="fas fa-info-circle" style="color: #00008b"></i>
                                  </span>
                                <span class="navi-text">Review Request</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="update_requests.length == 0">
                    <td class="text-center px-3" colspan="5">No items found.</td>
                  </tr>
                </template>

              </table>

              <b-pagination
                  v-if="update_requests.length > 0"
                  class="pull-right mt-7"
                  @input="getAllUpdateRequest"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="isLoading"
                  last-number
              ></b-pagination>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        width="900px" scrollable>
      <v-card>
        <v-card-title>
          <span class="h4 font-weight-medium">This request was made on <strong>{{updateRequestDetail.requested_date}}</strong> by <strong>{{updateRequestDetail.full_name}}</strong></span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <template>
              <p v-if="updateRequestDetail.exam_key && updateRequestDetail.exam_key!=''"><b>Enrolment key:</b> <a href="#" @click="getCandidateSummary"> {{updateRequestDetail.exam_key}}</a></p>
              <p v-if="updateRequestDetail.enroler_full_name && updateRequestDetail.enroler_full_name!=''"><b>Enroler:</b> {{updateRequestDetail.enroler_full_name}}</p>
              <p v-if="updateRequestDetail.product_name && updateRequestDetail.product_name!=''"><b>Exam:</b> {{updateRequestDetail.product_name}}</p>
              <p v-if="updateRequestDetail.enrolment_date && updateRequestDetail.enrolment_date!=''"><b>Enrolment date:</b> {{updateRequestDetail.enrolment_date}}</p>
<!--              <v-list-item v-if="updateRequestDetail.content.length > 0" subheader v-for="(content,index) in updateRequestDetail.content">
                <v-list-item-title>
                  <div class="font-weight-regular " v-html="content"></div>
                </v-list-item-title>
              </v-list-item>-->
              <div v-if="updateRequestDetail.content.length > 0" subheader v-for="(content,index) in updateRequestDetail.content">
               <div style="font-size: 15px" v-html="content"></div>
                <br>
              </div>
              <span class="h6 font-weight-regular" v-if="updateRequestDetail.content==0">No Data Changed</span>
            </template>

          </v-list>
          <v-container>
            <v-row>
              <v-col
                  cols="12">
                <ckeditor :config="editorConfig" v-model="remarks">
                </ckeditor>
              </v-col>
              <v-col cols="12">
                <v-list dense>
                  <v-subheader><span class="h5 font-weight-medium">Notes</span></v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <p class="subtitle-1 font-weight-regular">Remarks entered here will be sent to by email to the Enroller, with the result of their change request.</p>
                    </v-list-item-content>
                  </v-list-item>
<!--                  <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                      <p class="subtitle-1 font-weight-regular">Selecting ‘Hold’ will send the State Office Remark to the Enroller, and will change the request status to ‘On Hold’. The request can then be Approved or Not Approved at a later time.</p>-->
<!--                    </v-list-item-content>-->
<!--                  </v-list-item>-->
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text class="cancel-btn"
              medium  x-large
              @click="refresh">
            Close

          </v-btn>
          <v-btn :loading="buttonLoading" x-large dark color="red" @click="disapprovedUpdateRequest" rounded>
            <v-icon right dark small>
              fas fa-times
            </v-icon> Not approved
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark :loading="buttonLoading"
              x-large rounded
              @click="approvedUpdateRequest">
            <v-icon right small dark>
              fas fa-check
            </v-icon> Approved

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import UpdateRequestService from "@/services/update-request/UpdateRequestService";
import ScoreService from "@/services/score/score/ScoreService";
const updateRequest=new UpdateRequestService;
const score=new ScoreService();
export default {
  data(){
    return{
      update_requests:[],
      search:{
        candidate_info:'',
        type:'',
        score_id:'',
        request_date:'',
      },
      total: null,
      perPage: null,
      page: null,
      isLoading:false,
      dialog:false,
      changed_data:[],
      updateRequestDetail:{},
      scores:[],
      request_id:'',
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      type:'',
      types:[
        {value:'accountholder',title:'Enroller'},
        {value:'candidate',title:'Candidate'},
      ],
      remarks:'',
      request_date_menu:false,
      buttonLoading:false,
    }
  },
  methods:{

    searchUpdateRequest()
    {
      this.getAllUpdateRequest();
    },
    getCandidateSummary(examKey) {
      this.$router.push({
        name: "candidate-summary",
        params: { examKey: examKey },
      });
    },
    getAllScore() {
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          });
    },
    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: 'accountholder-edit',
        params: {accountholderId: accountHolderId}
      })
    },
    viewArchivedProfileEdits() {
      this.$router.push({
        name: "archived-update-requests",
      });
    },
    getAllUpdateRequest()
    {
      this.search.type='candidate';
      this.isLoading = true;
      updateRequest
          .paginate(this.search, this.page)
          .then(response => {
            this.update_requests=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {

          });
    },
    reviewRequest(itemId) {
      this.request_id=itemId;
      this.findDetail();
      this.dialog=true;

    },
    findDetail(){
      updateRequest
          .show(this.request_id)
          .then(response=>{
            this.updateRequestDetail=response.data.updateRequest;
            this.remarks=this.updateRequestDetail.remark;
            this.type=this.updateRequestDetail.type;
          })
    },


    approvedUpdateRequest()
    {
      this.buttonLoading=true;
      updateRequest
          .update(this.request_id,{'remark':this.remarks,'status':'approved','type':this.type})
          .then(response=>{
            this.refresh();
            this.$snotify.success("Request Approved");
          }).finally(() => this.buttonLoading = false);
    },
    disapprovedUpdateRequest()
    {
      updateRequest
          .update(this.request_id,{'remark':this.remarks,'status':'rejected','type':this.type})
          .then(response=>{
            this.refresh();
            this.$snotify.success("Request Not Approved");
          }).finally(() => this.buttonLoading = false);
    },
    refresh(){
      this.changed_data=[];
      this.remarks='';
      this.getAllUpdateRequest();
      this.dialog=false;
      this.type='';
    },
  },
  mounted() {

    this.getAllScore();
    this.getAllUpdateRequest();
    if(this.request_id){
      this.findDetail();
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  }
}
</script>